<template>
  <VLink
    v-if="isRouteSetting"
    @click="$router.push({ name: 'bidder-category-all' })"
    class="mb-60"
  >
    Назад
  </VLink>

  <TradeProcedureSidebar
    :title="getTitle"
    :condition-show="!isRouteSetting"
    :is-tabs="false"
    :class-title="isRouteSetting ? 'mb-70' : ''"
  >
    <template v-slot:header>
      <div class="admin__button">
        <VLink
          v-if="!isRouteSetting"
          icon="Setting"
          @click="$router.push({ name: 'bidder-category-setting' })">
          Настроить категории
        </VLink>
      </div>
    </template>
  </TradeProcedureSidebar>

</template>

<script>
import TradeProcedureSidebar from '@/components/views/TradeProcedure/VTradeProcedureSidebar'
import { TRADE_OFFER_URL, EXPAND_LOT } from '@/utils/consts'
import VLink from '@/components/ui/buttons/VLink'
import { computed } from 'vue'
import { useRoute } from 'vue-router'

export default {
  props: {
    title: {
      type: String,
      required: true
    }
  },
  setup () {
    const route = useRoute()

    const getTitle = computed(() => {
      return route.fullPath.includes('setting') ? 'Настроить категории' : 'Мои категории'
    })

    const isRouteSetting = computed(() => {
      return route.fullPath.includes('setting')
    })

    return {
      getTitle,
      isRouteSetting,
      TRADE_OFFER_URL,
      EXPAND_LOT
    }
  },
  components: {
    TradeProcedureSidebar,
    VLink
  }
}
</script>
